.notification-window, .notification-window-critical{
    background-color: #ffffff;
    min-width: 50vh;
    min-height: 20vh;
    z-index: 100;
    top:1rem;
    -webkit-box-shadow: 6px 4px 5px 5px #00000078;
    box-shadow: 6px 4px 5px 5px #00000078;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}

.slide-in {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.notification-window-critical{
    background: var(--red);
}
.message-title{
    color: #000;
    font-size: 1.5rem;
    font-weight: 900;
    text-transform: uppercase;
}
.message-content{
    color: rgb(216, 55, 55);
    font-size: 1rem;
    font-weight: bold;
}
.notification-window-critical > .message-content{
    color: #fff;
}


@keyframes slide-in {
    100% { transform: translateX(0%); }
    0% { transform: translateX(100%); }
}

@-webkit-keyframes slide-in {
    100% { transform: translateX(0%); }
    0% { transform: translateX(100%); }
}
