.card-icon-bg {
  color: var(--main-background);
  z-index: 0;
  font-size: 5rem;
}
.data-card-title,
.data-card-content,
.data-card-content-draw,
.data-card-content-loss {
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  margin: 0.2rem;
}
.data-card-content {
  color: var(--green);
}
.c-text{
  background-color: var(--main-background);
}
.data-card-content-loss {
  color: var(--red);
}

.data-card-content-draw {
  color: white;
}
.card-icon{
  margin-right: 1rem;
  width: 50px;
}
.bg-regular{
  filter: invert(8%) sepia(8%) saturate(6005%) hue-rotate(194deg) brightness(92%) contrast(94%);
}
.bg-cageball{
  filter: invert(32%) sepia(51%) saturate(433%) hue-rotate(6deg) brightness(97%) contrast(90%);
}
.bg-futsal{
  filter: invert(25%) sepia(20%) saturate(1898%) hue-rotate(114deg) brightness(86%) contrast(82%);
}
.push-up{
  z-index: 99;
}
.data-dashboard-card{
  margin-bottom: 1rem;
}


@media (max-width: 767.98px) {
  .c-text{
    font-size: 0.8rem;
  }
  .card-title{
    font-size: 1rem;
    text-transform: uppercase;
  }
}
