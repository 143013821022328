.game-CageBall{
    color: #3b996d;
    background-color: var(--main-background) ;
}
.game-Futsal{
    color: #ffca2c;
    background-color: var(--main-background) ;
}
.bg-main-backround{
    background-color: var(--main-background) !important;
  }
.date, .hours{
    font-size: 1rem;
  }
.game-type{
      font-weight: 900;
      text-transform: uppercase;
  }

.btn-bet{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    background-color: var(--main-background);
    color: #fff;
    margin: 3px;
    margin-top: 7px;
    min-width: 80px
}

.btn-bet.opt{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    background-color: var(--main-background);
    color: #fff;
    margin: 3px;
    margin-top: 7px;
    min-width: 80px
}

.btn-bet.opt:hover{
    border: 1px solid rgb(9, 164, 9);
    color: rgb(9, 164, 9)
}
.btn-bet.selected{
    border: 1px solid rgb(10, 224, 10)
}

.hours{
    margin-left: 1rem;
    color: var(--blue-light);
    font-weight: 900;
}

.location {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 6px;
}

.location_text {
    display: flex;
    gap: 12px;
    margin-top: 6px;
}

@media (max-width: 767.98px) {
    .date, .hours, .game-type{
        font-size: 0.8rem;
    }
    .location_text{
        flex-direction: column;
        gap: 0;
    }
  }