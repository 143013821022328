.new_game_input {
  display: flex;
  justify-content: between;
  gap: 5px;
}

.players_input {
  display: flex;
  justify-content: space-between;
}

.bottom_row {
  display: flex;
  margin-top: 6px;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767.98px) {
  .new_game_input {
    flex-direction: column;
    gap: 0;
    padding: 0 12px;
  }

  .players_input {
    flex-direction: column;
  }
}