.page-link {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  font-size: 1.2rem;
  font-weight: 900;
}
.page-link:hover , .page-link.active{
  z-index: 2;
  color: #000;
  background-color: #fff;
  border-color: transparent;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
  border-color: transparent;
}