.table-dark {
  --bs-table-bg: #13192f;
  --bs-table-striped-bg: #1e2742;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #98d7fc;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-border-bottom: #1c4862;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}
thead {
  border-bottom: 10px solid var(--bs-table-active-bg);
}
tr {
  border-bottom: 0.5px solid var(--bs-border-bottom);
}
td {
  min-width: 150px;
}
