.ql-stroke {
  stroke: var(--blue-light) !important;
}
.ql-picker-label::before {
  color: var(--blue-light) !important;
}
.ql-container.ql-snow {
  border: 1px solid var(--blue-light) !important;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.ql-toolbar {
  background-color: var(--component-background);
}
.ql-toolbar.ql-snow {
  border: 1px solid var(--blue-light) !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.settings-tab-container{
  background-color: var(--component-background);
  padding: 1rem;
}
.ql-editor{
  background-color: var(--main-background);
  min-height: 400px;
}