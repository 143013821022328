.nav-tabs{
    border-bottom: 1px solid var(--component-background);
}
.settings-tab {
    color: var(--blue-light);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    font-size: 0.7rem;
    padding: 0.6rem 0.7rem;
}

.settings-tab.active,  .nav-link:hover {
    background-color: var(--component-background) !important;
    border-color:var(--component-background) !important;
    color: #fff !important;
}

@media (max-width: 767.98px) {
    .settings-tab{
        font-size: 48%;
        padding: 0.4rem 0.3rem;
    }
  }