.btn-primary {
  background-color: var(--blue-light);
  color: var(--black);
  font-weight: bold;
  outline: none;
  border-color: transparent;
}
.btn-primary:focus,
.btn-secondary:focus,
.btn-secondary:active:focus {
  box-shadow: none;
  background: var(--blue-light);
  color: black;
}
.btn-primary:hover {
  background-color: var(--blue-dark);
  color: var(--white);
  border-color: var(--blue-light);
}
.btn > i {
  margin-right: 5px;
}
.btn-secondary {
  background-color: transparent;
  color: #fff;
  outline: none;
  border-color: transparent;
  padding: 0;
}
.btn-secondary:hover,
.btn-secondary:focus {
  color: var(--blue-light);
  background-color: transparent;
  border-color: transparent;
}

.btn:disabled{
  background-color: var(--polymetal);
}

@media (max-width: 767.98px) {
  .btn > i {
    margin-right: 0;
  }
}
