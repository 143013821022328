.location_label {
  margin: 0 6px;
}

.location_item_component {
  display: flex;
  align-items: center;
  gap: 3px;
}

.location_text {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  gap: 6px;
}

@media (max-width: 767.98px) {
  .location_label {
    margin: 2px 6px;
  }
  .location_item_component {
    flex-direction: column;
    align-items: flex-start;
  }
  .location_text {
    display: flex;
    flex-direction: row;
    margin-left: 6px;
  }

}
