.login-wrapper {
  max-width: 500px;
  width: 100%;
}
.input-group-text {
  background-color: var(--blue-light);
}
.is-invalid {
  background-color: var(--red);
  color: #fff;
}
.login-head {
  background-color: var(--main-background);

}
.login-title {
  color: var(--main-background);
}
.login-input {
  text-transform: uppercase;
}
.error-message {
  color: var(--red);
  text-transform: uppercase;
  font-weight: bold;
}