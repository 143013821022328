.lds-ripple-sm {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 18px;
}
.lds-ripple-sm div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple-sm 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-sm div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple-sm {
  0% {
    top: 12px;
    left: 12px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    opacity: 0;
  }
}