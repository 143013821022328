.sidebar-link {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
}
.sidebar-anchor {
  text-decoration: none;
}
.menu-wrap:hover {
  color: #fff;
  cursor: pointer;
}
@media (min-width: 767.98px) {
  .sidebar-anchor.active > * {
    background: rgb(30, 39, 66);
    background: linear-gradient(
      90deg,
      rgba(30, 39, 66, 1) 62%,
      rgba(19, 25, 47, 1) 100%
    );
  }
}
.sidebar-anchor.active {
  color: #fff;
}

@media (max-width: 1200px) {
  .sidebar-link {
    font-size: 0.8rem;
  }
  .regular-font {
    font-size: 1.2rem;
  }
}

@media (max-width: 780px) {
  .sidebar-link {
    font-size: 0.5rem;
  }
  .regular-font{
    font-size:1rem;
  }
}
