.settings-username{
    font-size: 3vw;
    font-weight: 900;
    text-transform: uppercase;
    font-style: italic;
}
.settings-email{
    font-size: 1vw;
    font-weight: bold;
    font-style: italic;
}
@media (max-width: 767.98px) {
    .settings-username {
      font-size: 7vw;
    }
    .settings-email{
      font-size:4vw;
    }
  }
  