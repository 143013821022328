:root {
  --primary: #931fa9;
  --secondary: #d8d807;
  --bg-light-gray: #f5f8fa;
  --white: #ffffff;
  --black: #000000;
  --purple-dark: #0e0025;
  --blue-dark: #06121f;
  --blue-lighter: #064281;
  --polymetal: #606d7f;
  --blue-light: #98d7fc;
  --main-background: #13192f;
  --component-background: #1e2742;
  --red: #ce0000;
  --green: #00b894;
  --gold: #e79d23;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background);
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-blue-dark {
  background-color: var(--blue-dark);
}
.bg-purple-dark {
  background-color: var(--purple-dark);
}
.bg-component-background {
  background-color: var(--component-background);
}

.bg-score-background {
  background-color: var(--component-background);
  border-radius: 30px;
}
.bg-vote-background{
  background: rgb(152,215,252);
background: linear-gradient(0deg, rgba(152,215,252,1) 0%, rgba(30,39,66,1) 100%);
}
.color-gold{
  color: var(--gold);
}
h1 {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
}
a {
  color: var(--blue-light);
}

.user-circle-color{
  color: #0079bf
}

.breadcrumb-title {
  font-size: 1rem;
  font-weight: 900;
  text-transform: uppercase;
  font-style: italic;
  color: var(--component-background);
}
.result-win {
  color: var(--green);
}
.result-loss {
  color: var(--red);
}
.result-draw {
  color: yellow;
}
.component-title {
  color: var(--component-background);
}
.reset-btn{
  color: var(--red);
}
.reset-btn:hover{
  color: #fff;
}
.barrel-img{
  max-width: 250px;
  padding: 0.5rem;
}
.mvp {
  background: -webkit-linear-gradient(#7e5f00, #fff400);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-team-white {
  background: #fff;
  background: -webkit-linear-gradient(105deg, #13192f 33%, #fff 33%);
  background: -o-linear-gradient(105deg, #13192f 33%, #fff 33%);
  background: -moz-linear-gradient(105deg, #13192f 33%, #fff 33%);
  background: linear-gradient(105deg, #13192f 33%, #fff 33%);
  border: none;
  border-left: 2px solid #fff;
}
.bg-team-black {
  background: #000;
  background: -webkit-linear-gradient(75deg, #000 66%, #13192f 66%);
  background: -o-linear-gradient(75deg, #000 66%, #13192f 66%);
  background: -moz-linear-gradient(75deg, #000 66%, #13192f 66%);
  background: linear-gradient(75deg, #000 66%, #13192f 66%);
  border: none;
  border-right: 3px solid #000;
}
.form-control,
.form-select {
  font-weight: 900;
  font-style: italic;
}
.form-select {
  text-transform: uppercase;
}
.optional > label {
  font-style: italic;
  color: #545b70;
}
.optional > .input-group > * {
  background-color: #545b70;
}

.progress-data-point-2,
.progress-data-point-1 {
  font-size: 1.2rem;
  font-weight: 900;
  color: var(--main-background);
}
.edit-result{
  margin-bottom: 0 !important;
  max-width: 100px;
  margin: 0px 10px;
}
.accordion-item{
  border:none;
}
th:first-child, td:first-child
{
  position:sticky;
  left:0px;
  
}
td:first-child{
  -webkit-box-shadow: inset -7px 3px 15px 4px #000; 
  box-shadow: inset -7px 3px 15px 4px #000;
}
.py-small{
  padding-left: 1px;
  padding-right: 1px;
}
.py-small-1{
  padding-left: 2px;
  padding-right: 2px;
}

.custom_modal {
  background-color: var(--component-background);
  padding: 12px;
  margin: 0 auto;
}

.modal-content {
  border: 2px solid var(--main-background);
  border-radius: 8px;
  margin: 0 auto;
}

.modal-header,
.modal-body,
.modal-footer {
  border: none;
}

.scrollable_content {
  height: 30vh; 
  overflow-y: auto;
}

.scrollable_content_sm {
  background-color: white;;
  height: 16vh; 
  overflow-y: auto;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 999; 
  display: block;
}

@media (max-width: 767.98px) {
  .mobile-nav{
    padding-bottom: 10px;
  }
  .main-container {
    margin-bottom: 4rem;
  }
  .profile-name{
    font-size: 1.5rem;
  }
  .stats-card {
    padding-bottom: 1rem;
  }
  .bg-score-background{
    font-size: 0.7rem;
  }
  .award{
    width: 30px !important
  }
}

