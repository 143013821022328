.circle {
    display: flex;
    width: 45px;
    height: 45px;
    background-color: var(--component-background);
    border-radius: 50%;
  }
  
  .text {
    margin: auto;
    font-size: 1.2rem;
    font-weight: bold;
  }