.mvp-tag{
    text-transform: uppercase;
    font-weight: 900;
    font-size: 0.8em;
    color: gray;
}
.game-type-tag{
    font-size: 0.8rem;
}
.mvp-card-accordion .result-win, .mvp-card-accordion .result-loss{
    font-size: 2rem;
}.gold-card {
    background: rgb(126, 95, 0);
    background: linear-gradient(
      0deg,
      rgba(126, 95, 0, 1) 0%,
      rgba(255, 244, 0, 1) 100%
    );
  }
  .gold-name {
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 0;
    font-weight: 900;
    text-shadow: 3px 2px 2px rgb(0, 0, 0, 0.4);
    text-decoration: none;
    color: #fff;
  
  }
  .gold-text {
    text-transform: uppercase;
    font-style: italic;
  }
  .award {
    max-width: 55px;
  }
  