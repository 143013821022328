.btn-vote {
  font-size: 1.5rem;
}
.btn-vote:focus {
  box-shadow: none;
}
.vote-wrapper {
  padding: 2em;
}
.bg-team-white{
  padding-right: 16px !important;
}
.bg-team-black{
  padding-left: 16px !important;
}
.score-input{
  max-width: 150px;
}

.btn-confirm{
  max-width: 150px;
  text-transform: uppercase;
  font-weight: 900;
  background-color: var(--main-background);
  color: #fff;
}
.btn-confirm:hover{
  background-color: var(--red);
  color: #fff;
}
@media (max-width: 767.98px) {
  .bg-vote-background{
    margin-left:-10px;
    margin-right: -10px;
    padding: 5px !important;
  }
  .bg-team-white{
    padding-right: 0px !important;
  }
  .bg-team-black{
    padding-left: 0px !important;
  }
  .vote-wrapper {
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .btn-vote {
    font-size: 1rem;
  }
  .player-name {
    font-size: 0.8rem;
  }
}

.error {
  color: red;
  border-color: red !important;
  z-index: 100;
}

.form-control:focus {
  border-color: white;
  z-index: 0;
}

