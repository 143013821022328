.to-compare {
    width: 150px;
}
.player-compare{
    font-weight: 900;
    min-width: 120px;
}
.list-stats {
    border-bottom: 1px solid #000;
}

.player_select {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767.98px) {
    .list-stats{
        font-size: 0.8rem;
    }

    .player_select{
        flex-direction: column;
    }
  }
  