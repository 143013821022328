.error {
    color: red;
    border-color: red !important;
    z-index: 100;
}

.form-control:focus {
    border-color: white;
    z-index: 0;
}

.italic {
    font-style: italic;
}

