.settings{
    top:90%;
    right:0;
    background-color: #000;
    color: var(--blue-light);
    z-index: 1070
}
.dropdown-item{
    color: var(--blue-light);
    text-transform: uppercase;
    font-weight: bold;
}
.btn-dropdown{
    color: #fff;
    font-size: 2rem;
}
.btn-dropdown:hover{
    color: var(--blue-light);
}
.btn-dropdown:focus{
    box-shadow: none;
}

.logout-drop{
    text-decoration: none;
}
.dropdown-caret{
 font-size: 0.8rem;
}