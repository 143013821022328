.accordion-item {
  background-color: var(--component-background);
}
.accordion-button {
  color: var(--blue-light);
  background-color: var(--main-background);
  padding: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
}
.accordion-button:disabled::after {
  display: none;
}

.accordion-button:not(.collapsed) {
  color: rgb(0, 0, 0);
  background-color: var(--blue-light);
}

.accordion-button.collapsed {
  color: rgb(0, 0, 0);
  background-color: var(--blue-light);
}
.date-wrapper {
  background-color: var(--component-background);
  text-align: center;
  color: #fff;
}
.date-day {
  font-size: 1.5rem;
  font-weight: 900;
  font-style: italic;
}
.month-year {
  color: #fff;
  font-size: 0.8rem;
  font-weight: 700;
}
.sport-type {
  color: var(--main-background);
  font-size: 1.2rem;
  font-weight: 800;
  text-transform: uppercase;
}
.bg-black {
  background-color: var(--black);
}
.list-group-item.bg-black {
  color: #fff;
}

.result {
  font-size: 2vw;
  font-weight: 900;
}
.game-item-white{
  text-shadow : 1px 1px #000;
}
.vote-count-wrapper{
  padding-left: 2px;
  padding-right: 2px;
}
.vote-count-wrapper i{
  font-size: 1rem;
}
.mvp-listed i{
  color: var(--gold);
}
.list-games{
  margin-bottom: 0.5rem;
}
.result-separator{
  font-size: 2vw;
}
@media (max-width: 767.98px) {
  .vote-count-wrapper i{
    font-size: 0.8rem;
  }
  .result {
    font-size: 7vw;
  }
  .list-group-item{
    font-size: 0.8rem;
    padding: 0.5rem 0.2rem;
    margin:0px 5px;
  }
  .accordion-body {
    padding: 10px 0px;
  }
  .header-result {
    padding: 0px;
  }
  .sport-type {
    font-size: 0;
  }
  .sport-type:first-letter {
    font-size: 1rem;
  }
}
