.border-dark-custom{
    border-color:var(--main-background) !important
    ;
}
.versus-type{
    font-size:1rem;
    font-weight:900;
}
@media (max-width: 767.98px) {
    .sport-types-found{
        font-size: 6vw;
    }
  }