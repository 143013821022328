.profile-progress {
  height: 3rem;
}
.barrel-litre {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  color: var(--component-background);
}


@media (max-width: 767.98px) {
  .filter_buttons{
    justify-content: center;
  }
}